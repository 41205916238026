<p-dialog
  [(visible)]="visible"
  [modal]="true"
  [style]="{ width: '25rem' }"
  [showHeader]="false"
  [closable]="false"
>
  <section>
    <div class="spinner-custom"></div>
    <span class="spinner-text"
      >Estamos cargando la información...<br />
      Un momento, por favor.</span
    >
  </section>
</p-dialog>
