import { Component, Input } from '@angular/core';
import { DialogModule } from 'primeng/dialog';

@Component({
  selector: 'slv-dialog-loader',
  standalone: true,
  imports: [DialogModule],
  templateUrl: './dialog-loader.component.html',
  styleUrls: ['./dialog-loader.component.scss'],
})
export class DialogLoaderComponent {
    @Input() visible: boolean = false;
}
