/**
 * Identifica (keys) a los dialogos de confirmación
 *
 */
type SLVTemplateDialogKey =
    | 'template-dialog-confirm'
    | 'template-dialog-question'
    | 'template-dialog-cancel'
    | 'template-dialog-notification';

export const SLVDialogKey = {
    /**
     * Obtiene el key del dialogo que solo tiene el boton de `confirmar`
     */
    CONFIRM: 'template-dialog-confirm' as SLVTemplateDialogKey,

    /**
     * Obtiene el key del dialogo que tiene los botones de `confirmar` y `cancelar`
     */
    QUESTION: 'template-dialog-question' as SLVTemplateDialogKey,

    /**
     * Obtiene el key del dialogo que tiene los botones para cancelar
     */
    CANCEL: 'template-dialog-cancel' as SLVTemplateDialogKey,

    /**
     * Obtiene el key del dialogo para mostrar los mensajes
     */
    NOTIFICATION: 'template-dialog-notification' as SLVTemplateDialogKey,
} as const;
